import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { RecuperarSenhaComponent } from './auth/recuperar-senha/recuperar-senha.component';
import { DefinirSenhaComponent } from './auth/definir-senha/definir-senha.component';
import { AuthentitcationGuard } from './core/guards/authentitcation.guard';

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  // { path: '', component: AppComponent },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
  {
    path: "auth",
    loadChildren: './auth/auth.module#AuthModule'
  }
  // { path: 'auth/login', component: LoginComponent, canActivate: [AuthentitcationGuard], canActivateChild: [AuthentitcationGuard] },
  // { path: 'auth/recuperar-senha', component: RecuperarSenhaComponent, canActivate: [AuthentitcationGuard], canActivateChild: [AuthentitcationGuard] },
  // { path: 'auth/definir-senha/:token', component: DefinirSenhaComponent, canActivate: [AuthentitcationGuard], canActivateChild: [AuthentitcationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
