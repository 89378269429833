import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationObject } from '../models/notification.model';
import { Serializer } from '../models/serializer.model';
import { ResourceService } from './resource.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ResourceService<NotificationObject> {

  constructor(
    httpClient: HttpClient,
    private socket: Socket

  ) {
    super(
      httpClient,
      environment.apis.udi_laudos_api,
      'v1',
      'notifications',
      new Serializer<NotificationObject>(new NotificationObject)
    )
  }

  // send(to:any, msg: any) {
  //   this.socket.emit(to, msg);
  // }

  getRealTimeMessage(): Observable<any> {
    return this.socket.fromEvent('message').pipe(map((data: any) => new NotificationObject(data)));
  }

}
