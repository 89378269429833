import { PaginatorI18n } from './core/models/PaginatorI18n.model';
import { MatPaginatorIntl } from '@angular/material';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthentitcationGuard } from './core/guards/authentitcation.guard';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { TokenInterceptor, TOKEN_INTERCEPTOR_BLACKLIST } from './core/interceptors/token.interceptor';
import { ConfigService } from './core/services/config.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import * as moment from 'moment';
import { SharedModule } from './shared/shared.module';
moment.locale('pt-BR')
registerLocaleData(ptBr)

const config: SocketIoConfig = {
  url: 'http://localhost:3333', options: {
    query: {
      user: 'teste'
    }
  }
};

export const BlackListToken: RegExp[] = [
  /(((https?):\/\/|www\.)viacep.com.br\/ws\/)/,
  /\/auth\/users\/login/,
  /\/auth\/revoke_token/,
  /\/refresh_token/
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      maxOpened: 3
    }),
    SocketIoModule.forRoot(config)
  ],
  providers: [
    AuthGuard,
    AuthentitcationGuard,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: PaginatorI18n },
    {
      provide: TOKEN_INTERCEPTOR_BLACKLIST,
      useValue: BlackListToken
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function ConfigLoader(configService: ConfigService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      configService.load(environment.CONFIG_FILE + `?v=${(new Date()).getTime()}`).then(() => {
        try {
          const configurations: any = configService.config;
          if (configurations.env) {
            if (typeof configurations.env === 'object') {
              var object = configurations.env;
              for (const key in object) {
                if (object.hasOwnProperty(key)) {
                  const element = object[key];
                  environment[key] = element;
                }
              }
            }
          }
          resolve();
        } catch (error) {
          reject();
          throw new Error(`Erro reescrever os valores de configuração!`);
        }
      }).catch(() => {
        reject();
        throw new Error(`Erro ao obter arquivo de configuração!`);
      });
    });
  };
}
