import { UserRole } from './../../core/enums/user.enum';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';

import { User } from '../../core/models/user.model';
import { tap } from 'rxjs/operators';
import { QueryOptions } from 'src/app/core/models/query-options.model';



@Injectable({
  providedIn: "root"
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  check(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  public checkResourcePrivelageAcl( privilageList: Array<string> ): boolean {
    let user;

    if (this.check()) {
      user = this.getUser();
    }

    if (user != null) {
      return privilageList.includes(UserRole[user.role]);
    }
    return false;
  }

  login(credentials: { email: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${environment.apis.udi_laudos_api}/auth/token`, credentials)
    .pipe(
      tap(data => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        localStorage.setItem('token_type', data.token_type);
        // data.user.role = (data.user.role == 1)? 'admin': 'customer';
        localStorage.setItem('user', btoa(JSON.stringify(data.user)));
      })
    )
  }

  resendEmailVerifying(credentials): Observable<boolean> {
    return this.http
      .post<any>(`${environment.apis.udi_laudos_api}/email/resend`, credentials);
  }

  verifyEmail(dataSend): Observable<boolean> {
    let queryString;
    if (dataSend.query) {
      queryString = QueryOptions.toQueryString(dataSend.query);
    }
    queryString = !!queryString ? `?${queryString}` : "";
    return this.http
      .get<any>(`${environment.apis.udi_laudos_api}/email/verify/${dataSend.id}/${dataSend.hash}${queryString}`);
  }

  logout(): void {
    this.http
      .post(`${environment.apis.udi_laudos_api}/logout`,{})
      .subscribe(
        resp => {
          localStorage.clear();
          this.router.navigate(["auth/login"], { replaceUrl: true });
        },
        error => {
          localStorage.clear();
          this.router.navigate(["auth/login"], { replaceUrl: true });
        }
      );
  }

  getUser(): User {
    try {
       return localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : null;
    }catch (e) {
        this.logout();
    }
  }

  getAccessToken() {
    try {
      return localStorage.getItem("access_token") || null;
    } catch (error) {
      return null;
    }
  }

  getRefreshToken() {
    try {
      return localStorage.getItem("refresh_token") || null;
    } catch (error) {
      return null;
    }
  }

  setUser(): Observable<any> {
    return this.http.get<any>(`${environment.apis.udi_laudos_api}/auth`)
    .pipe(
      tap(data => {
        if (data.user) {
          localStorage.setItem('user', btoa(JSON.stringify(data.user)));
          return true;
        }
        return false;
      })
    )

  }

  resetPassword(data: any) {
    return this.http.post<any>(`${environment.apis.udi_laudos_api}/password/email`, data);
  }

  setNewPassword(data: any) {
    return this.http.post<any>(`${environment.apis.udi_laudos_api}/password/reset`, data);
  }

}
