import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserRole } from 'src/app/core/enums/user.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public UserRole = UserRole;
  constructor(
    public authSvc: AuthService
  ) { }


  get imageProfile(){
    if(this.authSvc && this.authSvc.getUser().image_profile){
      return this.authSvc.getUser().image_profile.url;
    }
    return 'assets/img/user2-160x160.jpg';
  }

  ngOnInit() {
  }

}
