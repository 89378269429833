import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioRecordComponent } from './audio-record.component';

@NgModule({
  declarations: [AudioRecordComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AudioRecordComponent
  ]
})
export class AudioRecordModule { }
