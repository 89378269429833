import * as moment from 'moment';
import { Resource } from './resource.model';
import { User } from './user.model';

export enum NotificationEnum {
  REVISION = 1
}
export enum NotificationChannelEnum {
  PUSH = 1,
  EMAIL = 2
}
export enum NotificationStatusEnum {
  SEND = 1,
  READED = 2
}

export class NotificationObject extends Resource {
  title: string;
  message: string;
  type: NotificationEnum;
  channel: NotificationChannelEnum;
  status: NotificationStatusEnum;
  from: User;
  to: User;
  data: any;
  time: any;

  constructor(objectJson?: any) {
    super(objectJson);
  }

  fromJson(objectJson?: Resource) {
    for (const key in objectJson) {
      if (objectJson.hasOwnProperty(key)) {
        this[key] = objectJson[key];
      }
    }
    this.time = moment(this.created_at).fromNow();
  }

  // constructor(
  //   objectJson: any,
  //   @Optional() public router: Router
  // ) {
  //   Object.assign(this, objectJson)
  //   super()
  // }

  // handleClick(event?: any) {
  //   console.log(this)
  //   if (this.type == NotificationEnum.REVISION) {
  //     this.router.navigate(['/admin/medical-studies/edit/', this.data.medical_studies_id]);
  //   }
  // }

}
