import { InputFileModule } from './input-file/input-file.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { ImageUploadModule } from 'angular2-image-upload';
import { ControlSidebarComponent } from './control-sidebar/control-sidebar.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { SecuredDirective } from './directives/secured.directive';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoadingComponent } from './loading/loading.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { FilterListPipe } from './pipes/filter.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SelectMediaComponent } from './select-media/select-media.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {MatButtonModule} from '@angular/material/button';
import { InputImageModule } from 'src/app/shared/input-image/input-image.module';
import { DragAndDropDirective } from './drag-and-drop/drag-and-drop.directive';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatIconModule, MatNativeDateModule } from '@angular/material';
import {MatBadgeModule} from '@angular/material/badge';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { AudioRecordModule } from './audio-record/audio-record.module';


@NgModule({
  declarations: [
    SidebarComponent,
    ControlSidebarComponent,
    FooterComponent,
    HeaderComponent,
    LoadingComponent,
    SecuredDirective,
    PaginatorComponent,
    DateRangeComponent,
    FilterListPipe,
    SafeUrlPipe,
    SelectMediaComponent,
    DragAndDropDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ImageUploadModule.forRoot(),
    MatButtonModule,
    InputImageModule,
    InputFileModule,
    MatListModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    OverlayModule,
    MatButtonToggleModule,
    AudioRecordModule,
    MatIconModule
  ],
  exports: [
    SidebarComponent,
    ControlSidebarComponent,
    FooterComponent,
    HeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    SecuredDirective,
    PaginatorComponent,
    DateRangeComponent,
    MatSelectModule,
    FilterListPipe,
    SafeUrlPipe,
    SelectMediaComponent,
    ImageUploadModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    InputImageModule,
    InputFileModule,
    DragAndDropDirective,
    MatListModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTableModule,
    MatDatepickerModule,
    MatBadgeModule,
    OverlayModule,
    MatButtonToggleModule,
    AudioRecordModule,
    MatIconModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
