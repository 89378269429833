import { AfterViewInit, Component, OnInit, Inject, NgZone, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

export enum RecordStatus {
  RECORDING,
  PAUSE,
  STOP
}
declare var MediaRecorder: any

@Component({
  selector: 'app-audio-record',
  templateUrl: './audio-record.component.html',
  styleUrls: ['./audio-record.component.scss']
})
export class AudioRecordComponent implements OnInit, AfterViewInit {
  mediaRecorder: any;
  status: RecordStatus = RecordStatus.STOP
  RecordStatus = RecordStatus
  timerRecord = 0
  timeStart;
  intervalCount: any
  fileUrl: any

  // recordsList = []

  @Input() recordsList = []
  @Output() onSave = new EventEmitter()
  @Output() onRemove = new EventEmitter()

  constructor(
    public ngZone: NgZone,
    private sanitization: DomSanitizer
  ) { }

  ngOnInit() {
  }

  async ngAfterViewInit() {
    try {



    } catch (error) {
      console.log(error)
    }
  }



  async start() {
    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.mediaRecorder = new MediaRecorder(stream);
    let chunks = []
    this.mediaRecorder.ondataavailable = data => {
      chunks.push(data.data);
    }
    let $this = this;
    this.mediaRecorder.onstop = data => {
      const blob = new Blob(chunks, { type: 'audio/ogg; code=opus' });
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        let url: any = reader.result;
        $this.recordsList.push({
          blob,
          fileUrl: $this.sanitization.bypassSecurityTrustResourceUrl(url),
          name: (Math.random()).toString(36).replace('0.','')+'.ogg'
        })
        $this.ngZone.run(() => { })
      }
    }
    this.mediaRecorder.start()
    this.timeStart = moment()
    this.startCount()
    this.status = RecordStatus.RECORDING
  }

  get displayCount() {
    return moment().hour(0).minute(0).second(this.timerRecord).format('HH : mm : ss')
  }

  startCount() {
    this.intervalCount = setInterval((() => { this.timerRecord++ }).bind(this), 1000)
  }
  pauseCount() {
    clearInterval(this.intervalCount)
  }
  pause() {
    this.mediaRecorder.pause()
    this.pauseCount()
    this.status = RecordStatus.PAUSE
  }
  resume() {
    this.mediaRecorder.resume()
    this.startCount()
    this.status = RecordStatus.RECORDING
  }
  stop() {
    this.mediaRecorder.stop()
    this.pauseCount()
    this.timerRecord = 0;
    this.status = RecordStatus.STOP
  }

  saveRecord(event, item){
    console.log(event)
    console.log(item)
    this.onSave.emit(item)
  }

  removeRecord(event, item){
    console.log(event)
    console.log(item)
    let index = this.recordsList.findIndex(itemList => itemList.url == item.fileUrl)
    this.recordsList.splice(index, 1);
    this.onRemove.emit(item)
  }

}
