import { Directive, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserRole } from 'src/app/core/enums/user.enum';

@Directive({
  selector: '[secured]'
})
export class SecuredDirective {
  UserRole = UserRole;
  @Input( 'secured' )
  accessControlList: Array<string>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eltRef: ElementRef,
    private authSvc: AuthService
  ) {
  }

  ngAfterViewInit() {
    let hasAccess = this.authSvc.checkResourcePrivelageAcl( this.accessControlList );
      if ( !hasAccess ) {
          let el: HTMLElement = this.eltRef.nativeElement;
          if(el.parentNode){
            el.parentNode.removeChild( el );
          }else{
            el.remove();
          }
      }
  }

}
