import { NotificationService } from './../../core/services/notification.service';
import { NotificationObject, NotificationStatusEnum } from './../../core/models/notification.model';
import { UserRole } from './../../core/enums/user.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeService } from './../../core/services/theme.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: User;
  isDarkTheme: boolean = false;
  menuOpen: boolean = false;
  public UserRole = UserRole;
  notificationsList: NotificationObject[] = [];
  count_unreaded = 0
  constructor(
    public authSvc: AuthService,
    public themeSvc: ThemeService,
    public router: Router,
    private notificationSvc: NotificationService
  ) { }

  ngOnInit() {
    this.user = this.authSvc.getUser()
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.menuOpen = false;
        }
      }
    )
    let isDarkTheme = localStorage.getItem('dark_theme');

    this.isDarkTheme = (isDarkTheme && isDarkTheme != 'false')? true : false;
    this.setTheme()
    this.getNotifications();
  }

  logout() {
    this.authSvc.logout()
  }

  handleClick(item){
    try {
      let data = JSON.parse(item.data);
      this.router.navigate(['/admin/medical-studies/edit', data.medical_studies_id]);
    } catch (error) {
      this.router.navigate(['/admin/medical-studies']);
    }
  }

  async getNotifications(){
    let response = await this.notificationSvc.list({
      to_id: this.authSvc.getUser().id,
      status: NotificationStatusEnum.SEND,
      qtd: 5
    }).toPromise()
    if(response.meta)
    this.count_unreaded = response.meta.count_unreaded || 0;

    this.notificationsList = response.data
  }

  setTheme() {
    let bodyElement: HTMLElement = document.querySelector('body');
    localStorage.setItem('dark_theme', this.isDarkTheme.toString());
    if (this.isDarkTheme) {
      bodyElement.classList.add('dark-theme');
      this.themeSvc.setTheme({
        primary_color: "#115470",
        secondary_color: "#00b0b5",
        navbar_color: "#1f222f",
        navbar_color_contrast: "#fff",
        background_color: "#0b0d17",
        background_color_contrast: "#1f222f",
        background_color_hover: "#484848",
        font_color: "#919191",
      })
    } else {
      bodyElement.classList.remove('dark-theme');
      this.themeSvc.setTheme({
        primary_color: "#115470",
        secondary_color: "#00b0b5",
        navbar_color: "#115470",
        navbar_color_contrast: "#fff",
        background_color: "#E4E2E2",
        background_color_contrast: "#fff",
        background_color_hover: "#f5f5f5",
        font_color: "#333333",
      })
    }
  }

}
